export default (locale) => {
  switch (locale) {
    case 'sr-Latn':
    case 'sr':
      return import(/* webpackChunkName: "i18n.common.sr-Latn" */ './sr-Latn');
    case 'hr':
      return import(/* webpackChunkName: "i18n.common.hr" */ './hr');
    case 'ro':
      return import(/* webpackChunkName: "i18n.common.ro" */ './ro');
    case 'cs':
      return import(/* webpackChunkName: "i18n.common.cs" */ './cs');
    case 'ht':
      return import(/* webpackChunkName: "i18n.common.ht" */ './ht');
    case 'fr':
      return import(/* webpackChunkName: "i18n.common.ht" */ './fr');
    default:
      return import(/* webpackChunkName: "i18n.common" */ './en');
  }
};
